import { createContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import topRoutes from '../routes/top-routes.json'

export const NavButtonsContext = createContext()

export const NavButtonsContextProvider = ({ children }) => {

    const navigate = useNavigate('/')
    const location = useLocation()

    const [isNavigationButtonVisible, setIsNavigationButtonsVisible] = useState('hidden')
    const [isUpButtonVisible, setIsUpButtonsVisible] = useState('hidden')
    const [navigatedFrom, setNavigatedFrom] = useState('/')

    // useEffect(() => {
    //     const navigationEntries = window.performance.getEntriesByType('navigation');
    //     console.log("useeffect", location.pathname)

    //     if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
    //         let routeName = location.pathname.split('/')[1]
    //         if (routeName === 'app') {
    //             setIsNavigationButtonsVisible('hidden')
    //         }
    //         else {
    //             setIsNavigationButtonsVisible('visible')
    //             setIsUpButtonsVisible('visible')
    //         }
    //     }
    // }, [location.pathname]);

    useEffect(() => {

        let routeName = location.pathname.split('/')[1]
        setIsUpButtonsVisible('visible')
        setIsNavigationButtonsVisible('visible')

        if (routeName === 'app') {
            setIsNavigationButtonsVisible('hidden')
        }

    }, [location.pathname]);

    const navigatePageDown = (routeName) => {
        navigate('/' + routeName)
    }

    const navigateHome = () => {
        setNavigatedFrom(location.pathname.split('/')[1])
        navigate('/app')
    }

    const navigatePageUp = () => {
        let routeName = location.pathname.split('/')[1]

        if (routeName === 'app') {
            navigate('/')
            return
        }
        navigate(-1)
    }


    return (
        <NavButtonsContext.Provider value={{
            isNavigationButtonVisible, isUpButtonVisible, navigatedFrom,
            navigateHome,
            navigatePageDown,
            navigatePageUp
        }}>

            {children}
        </NavButtonsContext.Provider>)
}

export default NavButtonsContext