import '../../App.css'
import './content.css'
import Header from '../../components/header/Header';
import NavButtons from '../../components/nav-buttons/NavButtons';
import VideoContent from '../../components/videos-content/VideoContent';

import VideoContext from '../../context/VideoContext';
import { useContext } from 'react';


function Videos() {

    const { videoClassName, setVideoClassName } = useContext(VideoContext)

    const firebase_connect = require('./assets/firebase-connect.mp4')
    const firebase_disconnect = require('./assets/firebase-disconnect.mp4')
    const alhambra = require('./assets/alhambra.mp4')
    
    const dashboards_add = require('./assets/dashboards-add.mp4')
    const dashboards_hide = require('./assets/dashboards-hide.mp4')
    const dashboards_reorder = require('./assets/dashboards-reorder.mp4')

    const controls_add = require('./assets/controls-add.mp4')
    const controls_reorder = require('./assets/controls-reorder.mp4')
    const controls_delete = require('./assets/controls-delete.mp4')

    const dashboard_delete = require('./assets/dashboard-delete.mp4')
   
   

    return (
        <div className="App">
            <NavButtons></NavButtons>
            <Header title="Videos" ></Header>
            <div className='video-sidebar'>
                <div className="sidebar-button" onClick={() => setVideoClassName('firebase_connect')}>Connect to Firebase </div>
                <div className="sidebar-button" onClick={() => setVideoClassName('manage_dashboards')}>Manage Dashboards </div>
                <div className="sidebar-button" onClick={() => setVideoClassName('edit_a_dashboard')}>Edit a Dashboard </div>
                <div className="sidebar-button" onClick={() => setVideoClassName('delete_a_dashboard')}>Delete a Dashboard </div>
            </div>

            <div className='video-content'>
                <div className={videoClassName === 'firebase_connect' ? '' : 'hidden'}>
                    <VideoContent video={firebase_connect} title={'Connect to Firebase'}></VideoContent>
                </div>
                <div className={videoClassName === 'firebase_connect' ? '' : 'hidden'}>
                    <VideoContent video={firebase_disconnect} title={'Disconnect Firebase'}></VideoContent>
                </div>

                {/* <div className={videoClassName === 'firebase_connect' ? '' : 'hidden'}>
                    <VideoContent video={alhambra} title={'Alhambra'}></VideoContent>
                </div> */}

                <div className={videoClassName === 'manage_dashboards' ? '' : 'hidden'}>
                     <VideoContent video={dashboards_add} title={'Add Dashboards'}></VideoContent>
                </div>
                <div className={videoClassName === 'manage_dashboards' ? '' : 'hidden'}>
                     <VideoContent video={dashboards_hide} title={'Hide Dashboards'}></VideoContent>
                </div>
                <div className={videoClassName === 'manage_dashboards' ? '' : 'hidden'}>
                     <VideoContent video={dashboards_reorder} title={'Reorder Dashboards'}></VideoContent>
                </div>
                {/* <div className={videoClassName === 'manage_dashboards' ? '' : 'hidden'}>
                     <VideoContent video={dashboards_reorder} title={'Reorder Dashboards'}></VideoContent>
                </div> */}


                <div className={videoClassName === 'edit_a_dashboard' ? '' : 'hidden'}>
                     <VideoContent video={controls_add} title={'Add Controls'}></VideoContent>
                </div>
                 <div className={videoClassName === 'edit_a_dashboard' ? '' : 'hidden'}>
                     <VideoContent video={controls_reorder} title={'Reorder Controls'}></VideoContent>
                </div>
                <div className={videoClassName === 'edit_a_dashboard' ? '' : 'hidden'}>
                     <VideoContent video={controls_delete} title={'Delete Controls'}></VideoContent>
                </div>

                <div className={videoClassName === 'delete_a_dashboard' ? '' : 'hidden'}>
                     <VideoContent video={dashboard_delete} title={'Delete a Dashboard'}></VideoContent>
                </div>
               
                {/* <div className={videoClassName === 'edit_a_dashboard' ? '' : 'hidden'}>
                     
                     <h3>Videos will be posted soon</h3>
                </div> */}


                
                
            </div>
        </div>
    );
}

export default Videos