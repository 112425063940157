import './nav-buttons.css'

import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import NavButtonsContext from '../../context/NavButtonsContext'

function NavButtons() {

    const { navigateHome, navigatePageUp, isNavigationButtonVisible, isUpButtonVisible } = useContext(NavButtonsContext)
    const [upButtonLabel, setUpButtonLabel] = useState('<<')
    const location = useLocation()

    useEffect(() => {
        let routeName = location.pathname.split('/')[1]
        if (routeName === 'app') {
            setUpButtonLabel('Home')
        } else {
            setUpButtonLabel('<<')
        }
        
    }, [location.pathname])

    return (
        <div className={'container'}>
            <button onClick={navigateHome} style={{ visibility: isNavigationButtonVisible }}>{'App Navigation'}</button>
            <button className="nav-button" onClick={navigatePageUp} style={{ visibility: isUpButtonVisible }}>{upButtonLabel}</button>
        </div>)
}

export default NavButtons;